import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
export default class Movies extends Component {

    state = {
        movies: [],
    isLoaded: false,
    error: null,
    };

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/v1/movies`)
            //.then((response) => response.json())
            .then((response) => {
                console.log("status code is", response.status);
                if (response.status !== "200") {
                    let err = Error;
                    err.message = "invalid response code: " + response.status;
                    this.setState({error: err});
                }
                return response.json();
            })
            .then((json) => {
                this.setState({
                    movies: json.movies,
                    isLoaded: true,
                },
                    (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    }
                    );
            });
/*        this.setState({
            movies: [
                {id:1, title: "The Shawshank Redemption", runtime: 142},
                {id:2, title: "The Godfather", runtime: 175},
                {id:3, title: "The Dark Knight", runtime: 153},
            ]
        })*/
    }

    render() {
        const { movies, isLoaded, error } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return  <p>Loading...</p>
        } else {
            return (
                <Fragment>
                    <h2>choose a movie</h2>
                    <ul>
                        {movies.map((m) => ( /*{this.state.movies.map((m) => (*/
                            <li key={m.id}>
                                <Link to={`/movies/${m.id}`}>{m.title}</Link>
                            </li>
                        ))}
                    </ul>

                </Fragment>
            );
        }
    }
}