import React, {Component} from "react";

export default class Admin extends Component {

    render() {

        return (
            <h2>admin</h2>
        );
    }
}