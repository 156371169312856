import React, {Component} from "react";

export default class Home extends Component {

    render() {

        return (
            <h2>homee</h2>
        );
    }
}